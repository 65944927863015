<template>
  <v-app id="main-app">
    <v-container class="fill-height" fluid>
      <v-card class="mx-auto px-10 pb-9 reset-password-card" width="450px">
        <v-card-title class="justify-center pt-12"
          ><img
            v-bind:src="logo.src"
            v-bind:style="{ width: logo.width, height: logo.height }"
        /></v-card-title>
        <v-card-subtitle class="text-center py-6 title-login"
          >Set new password</v-card-subtitle
        >
        <v-card-text class="text-center">
          <v-form ref="form1" @submit.prevent="login">
            <v-text-field
              v-model="password"
              label="Password"
              placeholder=" "
              name="password"
              background-color="#E8E8E8"
              rounded
              outlined
              style="
                border-radius: 12px !important;
                font-family: 'Catamaran', serif !important;
              "
              spellcheck="false"
              :autocomplete="showPassword ? 'off' : 'current-password'"
              :rules="[rules.passwordRequired]"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              @keyup.enter="clickButton"
            />
            <v-text-field
              v-model="confirmPassword"
              label="Re-type password"
              placeholder=" "
              name="password"
              background-color="#E8E8E8"
              rounded
              outlined
              style="
                border-radius: 12px !important;
                font-family: 'Catamaran', serif !important;
              "
              spellcheck="false"
              :autocomplete="showNewPassword ? 'off' : 'current-password'"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.confirmPasswordRequired]"
              :type="showNewPassword ? 'text' : 'password'"
              @click:append="showNewPassword = !showNewPassword"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text class="ma-2 title-reset-password">
            <!-- <v-icon class="primary--text mr-3">mdi-arrow-left-bottom</v-icon> -->
            <span>Go to sign in page</span>
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            large
            class="ma-3 button-login"
            @click="clickButton"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-footer color="transparent" absolute padless>
        <v-col class="text-center black--text" cols="12">
          &#183; <img :src="logo_bridge" height="24px" /> &#183;
        </v-col>
      </v-footer>
    </v-container>
    <Alert
      :title="snackTitle"
      :status="snackStatus"
      :color="snackColor"
    ></Alert>
  </v-app>
</template>
<script>
import _axios from "@/plugins/axios";
import logo_bridge from "../../../public/logo_bridge.png";
import Alert from "@/views/Client/components/Alert";
const { LOGIN_LOGO } = require(`@/themes/VitalCheckups/image-paths`);
export default {
  components: {
    Alert,
  },
  data() {
    return {
      logo: LOGIN_LOGO,
      showForm: 1,
      showPassword: false,
      showInputs: false,
      logo_bridge: logo_bridge,
      rules: {
        passwordRequired: (value) => {
          if (value.length < 8) return "This fields length must be > 8";
          return !!value;
        },
        confirmPasswordRequired: (value) => {
          if (this.password !== value) {
            return "The two fields do not match";
          }
          return !!value;
        },
      },
      password: "",
      confirmPassword: "",
      showNewPassword: false,
      snackStatus: false,
      snackTitle: "",
      snackColor: "",
    };
  },
  async mounted() {
    await _axios.get(this.$route.fullPath.split("api")[1]);
  },
  methods: {
    async changePassword() {
      this.snackStatus = false;
      const token = this.$route.query.validationtoken;
      const body = {
        NewPassword: this.confirmPassword,
        ValidationToken: token,
      };
      await this.$store
        .dispatch("users/changePasswordWithValidationToken", body)
        .then(() => {
          this.snackStatus = true;
          this.snackColor = "teal darken-2";
          this.title = this.$t("passwordChangedSuccessfully");
          this.$router.push({ name: "Login" });
        })
        .catch(() => {
          this.snackStatus = true;
          this.snackColor = "deep-orange darken-4";
          this.title = this.$t("passwordCouldNotBeChanged");
        });
    },
    clickButton() {
      this.changePassword();
    },
  },
};
</script>
<style scoped>
#main-app {
  background: linear-gradient(#e8e8e8, #d6d6d6);
}
</style>